import React from "react";
import SEO from "components/seo";
import Button from "components/ui/button/Button";
import AppContentContainer from "components/layouts/appLayout/AppContentContainer";
import D20Image from "components/images/D20";

const FoundryPage = () => (
  <AppContentContainer spacing="extra">
    <SEO title="Play D&D" />
    <h1>Play D&D</h1>
    <D20Image />
    <p>
      <b>
        Click the button below to launch FoundryVTT - where we'll actually be
        playing D&D!
      </b>
      <br />
      <br />
    </p>
    <Button theme="secondary" rounded href="https://foundry.coinstone.app">
      Launch Foundry
    </Button>
  </AppContentContainer>
);

export default FoundryPage;
