import React from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import cx from "classnames";
import Footer from "components/ui/footer/Footer";
import { IState } from "store";
import MobileMenu from "components/layouts/appLayout/MobileMenu";
import { layoutService } from "services/LayoutService";
import styles from "./AppContentContainer.module.scss";

export const AppContentContainer = (props: {
  className?: string;
  spacing?: "none" | "small" | "normal" | "large" | "extra";
  maxWidth?: boolean;
  children: any;
}) => {
  const { isMobileLayout, isMobileOpened } = useSelector(
    (s: IState) => s.app.layout
  );

  const toggleMobileNav = () => {
    layoutService.expandMobileSidebarToggle(!isMobileOpened);
  };
  return (
    <>
      <div className={styles.contentShadow} />
      <div className={cx(props.className, styles.scrollWrapper)}>
        <div
          className={cx(
            styles.contentContainer,
            isMobileOpened && styles.mobileOpened
          )}
        >
          <motion.div
            className={cx(
              styles.content,
              props.spacing && styles.spacing,
              props.spacing && styles[props.spacing],
              props.maxWidth && styles.max
            )}
            {...animations}
          >
            {props.children}
            {isMobileLayout && (
              <MobileMenu
                toggleMobileNav={toggleMobileNav}
                isMobileOpened={isMobileOpened}
              />
            )}
          </motion.div>
        </div>

        <Footer />
      </div>
    </>
  );
};
const animations = {
  initial: { x: 10, y: 10, opacity: 0 },
  animate: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.2, type: "spring" },
  },
  exit: { opacity: 0 },
};

export default AppContentContainer;
