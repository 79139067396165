import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const D20Image = ({ className }: { className?: string }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "d20.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={className}>
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        style={{ maxWidth: 150 }}
      />
    </div>
  );
};

export default D20Image;
