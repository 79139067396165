import React from "react";
import classNames from "classnames";
import styles from "./Footer.module.scss";
import Container from "components/layouts/Container";

interface Props {
  dark?: boolean;
}

const Footer = ({ dark = false }: Props) => {
  return (
    <div className={classNames(styles.footer, dark && styles.footerDark)}>
      <Container>
        <div className={styles.inner}>
          {/*<a href="/" className={styles.textLogo}>*/}
          {/*  {title}*/}
          {/*</a>*/}
        </div>
      </Container>
    </div>
  );
}

export default Footer;
